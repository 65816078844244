<template>
    <div>
        <span>{{label}}</span>
        <vue-editor
            v-model="content"
            use-custom-image-handler
            :editor-toolbar="customToolbar"
            :error-messages="textErrors"
            @imageAdded="handleImageAdded"
        ></vue-editor>
        <p
         v-if="validation.description"
         class="error--text mt-1 text-caption font-weight-normal"
        >
            {{ validation.description[0] }}
        </p>
    </div>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
    name: "TextEditor",
    props: {
        modelValue: {
            type: String,
            required: false,
            default: "",
        },

        currentValue: {
            type: String,
            required: false,
            default: "",
        },

        inValid: {
            type: Boolean,
            required: false,
            default: true,
        },

        label: {
            type: String,
            required: false,
            default: "Description"
        },

        validation: {
            type: [Array, Object, File],
            required: false,
            default: function() {
                return {}
            },
        },
        placeholder: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    components: {
        VueEditor,
    },

    data() {
        return {
            content: this.currentValue,
            customToolbar: [
                // custom button values
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                ["bold", "italic", "underline", "strike"], // toggled buttons
                ["blockquote", "code-block"],

                [{ list: "ordered" }, { list: "bullet" }],
                [{ script: "sub" }, { script: "super" }], // outdent/indent

                [{ color: [] }, { background: [] }],
                ["link"],
                ["clean"],
            ],
        };
    },

    computed: {
        descriptionInvalid() {
            return this.content === "";
        },
        textErrors() {
            const errors = [];
            // if(!this.$v.content.$dirty) return errors;
            if(this.validation.description) {
                errors.push(this.validation?.description[0]);
            }
            return errors;
        }
    },

    methods: {
        handleImageAdded(file, Editor, cursorLocation, resetUploader) {
            // An example of using FormData
            // NOTE: Your key could be different such as:
            // formData.append('file', file)
            //     var formData = new FormData();
            //     formData.append("image", file);
            //     axios({
            //         url: "https://fakeapi.yoursite.com/images",
            //         method: "POST",
            //         data: formData,
            //     })
            //         .then((result) => {
            //             const url = result.data.url; // Get url from response
            //             Editor.insertEmbed(cursorLocation, "image", url);
            //             resetUploader();
            //         })
            //         .catch((err) => {
            //             console.log(err);
            //         });
            // },
        },
    },

    watch: {
        content(newVal) {
            this.$emit("input", newVal);
            this.$emit("update:inValid", this.descriptionInvalid);
        },
        currentValue(newVal) {
            this.content = newVal;
        },
    },

    mounted() {
        if (this.currentValue === "" && this.placeholder) this.content = "This is my description";
    },
};
</script>

<style scoped>
</style>
